import { useState } from "react";

import {
  AccountBox,
  HelpCenter,
  Logout,
  Settings,
  QuestionAnswer,
} from "@mui/icons-material";
import {
  Avatar,
  Divider,
  ListItem,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import { Auth } from "aws-amplify";
import { useRouter } from "next/router";
import { useTranslation } from "react-i18next";

import { clearCache, useUserInfo } from "@/hooks/useUserInfo";
import { isOrganizationAdmin } from "@/utils/user-utils";

export interface UserMenuProps {}

export const UserMenu = ({}: UserMenuProps) => {
  const { t } = useTranslation();
  const router = useRouter();

  const { data } = useUserInfo();

  const [anchorEl, setAnchorEl] = useState<any>(null);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onSignOut = async () => {
    await clearCache(true);
    await Auth.signOut();
  };

  return (
    <>
      <ListItem
        data-test="user-menu"
        onClick={(event) => setAnchorEl(event.currentTarget)}
        sx={{
          width: "auto",
          paddingTop: 0,
          paddingBottom: 0,
          borderLeft: (theme) => `1px solid ${theme.palette.divider}`,
          cursor: "pointer",
        }}
      >
        <ListItemIcon sx={{ minWidth: 32 }}>
          <Avatar
            src={data?.user?.organization?.logo}
            sx={{
              mr: 1,
              width: 32,
              height: 32,
              "& img": { objectFit: "contain" },
            }}
          />
        </ListItemIcon>
        <ListItemText
          primary={
            <Typography data-test="organizationName" fontWeight="bold">
              {data?.user?.organization?.name}
            </Typography>
          }
          secondary={
            <Typography data-test="username" variant="body2">
              {data?.user?.username}
            </Typography>
          }
        />
      </ListItem>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={!!anchorEl}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{ sx: { boxShadow: 1 } }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        {isOrganizationAdmin(data?.user?.roles) && (
          <MenuItem
            data-test="navigate-Manage"
            onClick={() => router.push("/manage")}
            onMouseOver={() => router.prefetch("/manage")}
          >
            <ListItemIcon>
              <Settings fontSize="small" />
            </ListItemIcon>
            {t("manage")}
          </MenuItem>
        )}

        <MenuItem
          data-test="navigate-Account"
          onClick={() => router.push("/account")}
          onMouseOver={() => router.prefetch("/account")}
        >
          <ListItemIcon>
            <AccountBox fontSize="small" />
          </ListItemIcon>
          {t("account")}
        </MenuItem>

        <Divider />

        <MenuItem
          data-test="navigate-Help"
          onClick={() => router.push("/help")}
          onMouseOver={() => router.prefetch("/help")}
        >
          <ListItemIcon>
            <HelpCenter fontSize="small" />
          </ListItemIcon>
          {t("help")}
        </MenuItem>

        <MenuItem
          data-test="navigate-FAQ"
          onClick={() => router.push("/help/faq")}
          onMouseOver={() => router.prefetch("/help/faq")}
          sx={{ pl: 3 }}
        >
          <ListItemIcon>
            <QuestionAnswer fontSize="small" />
          </ListItemIcon>
          {t("faq")}
        </MenuItem>

        <Divider />

        <MenuItem onClick={onSignOut}>
          <ListItemIcon>
            <Logout fontSize="small" />
          </ListItemIcon>
          {t("logout")}
        </MenuItem>
      </Menu>
    </>
  );
};
