import React, { useContext } from "react";

import { Divider, Grid, Stack, Typography } from "@mui/material";
import Head from "next/head";
import { serverSideTranslations } from "next-i18next/serverSideTranslations";

import type {
  AggregateStatisticsSetting,
  HeaderLabelSetting,
  PortfolioMapSetting,
} from "@/apis/services/OrganizationService";
import * as AssetHeader from "@/components/asset/asset-header";
import { DefaultLoader } from "@/components/common/loading/DefaultLoader";
import { OrganizationSettingsContext } from "@/components/context/organizationSettingsContext";
import { RiskExposureBarGraph } from "@/components/high-risks/exposure/RiskExposureBarGraph";
import { HighRisksTable } from "@/components/high-risks/HighRisksTable";
import { RelativeRiskDistributionPlot } from "@/components/high-risks/RelativeRiskDistributionPlot";
import { PageLayout } from "@/components/layouts/PageLayout";
import { PortfolioLayout } from "@/components/layouts/PortfolioLayout";
import GroupMap from "@/components/map/GroupMap/GroupMap";
import { AREA_DISPLAY_LABEL } from "@/components/shared/user/user-settings";
import { useAllAssetsByAttributes } from "@/hooks/useAllAssets";
import { usePortfolioViewer } from "@/hooks/useAllOrganizations";
import { useUserInfo } from "@/hooks/useUserInfo";
import { getAreaValue } from "@/utils/measurement/area-utils";
import { formatMoney } from "@/utils/utils";

import type { Page } from "./_app";

const Portfolio: Page = () => {
  const { data } = useUserInfo();

  const { data: assets, isLoading } = useAllAssetsByAttributes(
    [
      "latitude",
      "longitude",
      "occ_area",
      "ownership",
      "business_group",
      "monthly_revenue",
      "total_building_population",
      "gross_asset_value",
      "replacement_cost",
    ],
    {
      limit: 99999,
      cursor: "",
    }
  );

  const { data: viewer } = usePortfolioViewer(data?.user?.organization?.id);

  const { organizationSettings } = useContext(OrganizationSettingsContext);
  const settings = organizationSettings?.views.portfolio.settings;
  const mapSettings = settings.portfolio_map as PortfolioMapSetting;
  const headerLabels = settings.header_labels as HeaderLabelSetting;
  const aggregateStatisticsSettings =
    settings.aggregate_statistics as AggregateStatisticsSetting;

  const orgName = data?.user?.organization?.name ?? "";

  const getOrganization = () => {
    return data?.user?.organization;
  };

  if (!getOrganization()) {
    return <></>;
  }

  return (
    <>
      <Head>
        <title>{`${orgName} Portfolio | Iris`}</title>
      </Head>
      <GroupMap assets={assets} mapSettings={mapSettings} />
      <AssetHeader.AssetHeader>
        <Stack direction="row" spacing={3}>
          <Typography variant="h3" component="h1" fontSize={28} marginTop={0}>
            All assets
          </Typography>

          <AssetHeader.ActionButtons>
            <AssetHeader.Label
              label={headerLabels?.total_assets}
              value={viewer?.total_assets ?? "-"}
              show={aggregateStatisticsSettings?.total_assets}
            />
            <AssetHeader.Label
              label={headerLabels?.total_population}
              value={viewer?.total_population ?? "-"}
              show={aggregateStatisticsSettings?.total_population}
            />
            <AssetHeader.Label
              label={headerLabels?.total_asset_value}
              value={`${formatMoney(viewer?.total_value)}`}
              show={aggregateStatisticsSettings?.total_asset_value}
            />
            <AssetHeader.Label
              label={headerLabels?.total_replacement_value}
              value={`${formatMoney(viewer?.total_replacement_cost)}`}
              show={aggregateStatisticsSettings?.total_replacement_value}
            />
            <AssetHeader.Label
              label={headerLabels?.total_occupied_area}
              value={
                `${getAreaValue(
                  viewer?.total_occupied_area
                )} ${AREA_DISPLAY_LABEL}` ?? "-"
              }
              show={aggregateStatisticsSettings?.total_occupied_area}
            />
            <AssetHeader.Label
              label={headerLabels?.top_uses}
              value={
                viewer?.primary_use
                  ?.sort((a: any, b: any) => b.count - a.count)
                  .slice(0, 3)
                  .join(", ") || "-"
              }
              show={aggregateStatisticsSettings?.top_uses}
            />
          </AssetHeader.ActionButtons>
        </Stack>
        <Divider style={{ marginTop: 10 }} />
      </AssetHeader.AssetHeader>

      {isLoading ? (
        <DefaultLoader />
      ) : (
        assets.length > 0 && (
          <Grid container spacing={7} paddingTop={4}>
            <Grid item xs={12} md={6}>
              <RiskExposureBarGraph
                assets={assets}
                byHazard
                direction="horizontal"
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <RiskExposureBarGraph assets={assets} />
            </Grid>

            <Grid item xs={12}>
              <HighRisksTable assets={assets} />
            </Grid>

            <Grid item xs={12}>
              <RelativeRiskDistributionPlot assets={assets} />
            </Grid>
          </Grid>
        )
      )}
    </>
  );
};

export default Portfolio;

Portfolio.getLayout = (page: any) => {
  return (
    <PageLayout>
      <PortfolioLayout>{page}</PortfolioLayout>
    </PageLayout>
  );
};

export const getStaticProps = async ({ locale }: any) => ({
  props: {
    ...(await serverSideTranslations(locale, ["common"])),
  },
});
