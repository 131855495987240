import type {
  AssetBasicResponse,
  CreateAssetResponse,
  SiteCentroidRequest,
} from "@/apis/services/AssetService";
import {
  HazardType,
  ShapeType,
  type ConsequenceType,
  type RatingInfo,
} from "@/apis/services/HazardService";
import { IntensityMeasureType } from "@/apis/services/HazardService";
import type {
  AssetHeaderLabelSetting,
  HazardsEnum,
} from "@/apis/services/OrganizationService";
import type { ExportMap } from "@/components/asset/AssetExportPopup";
import type { Ids } from "@/components/asset/PreviewPopupClimateScenario";
import type { FootprintByGeolocationQuery } from "@/components/forms/asset/constants";
import type { HighTideParams } from "@/components/high-risks/types";
import {
  ASSESSMENT_PREFIX,
  CONSEQUENCE_PREFIX,
  HAZARD_PREFIX,
  INTENSITY_MEASURE_PREFIX,
  RETURN_PERIOD_PREFIX,
} from "@/constants/preview";
import { AGGREGATE, ALL_HAZARDS } from "@/constants/risks";
import { formatMoney, formatNumber, isEmptyObject } from "@/utils/utils";

export const hasGeoLocation = (lat?: number | null, long?: number | null) => {
  if (lat === undefined || long === undefined) return false;

  if (lat === null || isNaN(lat)) return false;
  if (long === null || isNaN(long)) return false;

  return true;
};

export const getPopulation = (
  assets: AssetBasicResponse[] | undefined
): number => {
  if (!assets) return 0;
  return assets.reduce((agg, c) => agg + (c.total_building_population ?? 0), 0);
};

export const getGrossValue = (assets: AssetBasicResponse[] | undefined) => {
  if (!assets) return 0;
  return assets.reduce((agg, c) => agg + (c.gross_asset_value ?? 0), 0);
};

export const getReplacementValue = (
  assets: AssetBasicResponse[] | undefined
) => {
  if (!assets) return 0;
  return assets.reduce((agg, c) => agg + (c.replacement_cost ?? 0), 0);
};

export const getOccupationalArea = (
  assets: AssetBasicResponse[] | undefined
) => {
  if (!assets) return 0;
  return assets.reduce((agg, c) => agg + (c.occ_area ?? 0), 0);
};

export const assetsTransform = (a: any[]) =>
  a.map((b: any) => createAssetEntry(b));

export const createAssetEntry = (asset: any) => {
  return {
    ...asset,
    city: asset.city,
    nickname: asset.nickname,
    year_of_construction: asset.year_of_construction,
    total_building_population: asset.total_building_population,
    n_floors: asset.n_floors,
    occ_area: asset.occ_area,
    empty_consequence: "",
  };
};

export const createAssetLoadingEntries = (
  assets?: any[],
  o?: HighTideParams
) => {
  return assets?.map((a: any) => createAssetLoadingEntry(a, o)) || [];
};

const createAssetLoadingEntry = (asset: any, o?: HighTideParams) => {
  return {
    ...getRiskColumnsPlaceholder("loading", o),
    ...asset,
  };
};

export const createEnhancedAssetEntry = (
  asset: any,
  resp?: RatingInfo[],
  o?: HighTideParams
) => {
  return {
    ...asset,
    ...getRiskColumnsValue(asset, resp, o),
  };
};

export const createEnhancedAssetErrorEntries = (
  assets?: any[],
  o?: HighTideParams
) => {
  return assets?.map((a: any) => createEnhancedAssetErrorEntry(a, o));
};

const createEnhancedAssetErrorEntry = (asset: any, o?: HighTideParams) => {
  return {
    ...asset,
    ...getRiskColumnsPlaceholder("error", o),
  };
};

const getRiskColumnsValue = (
  asset: any,
  resp?: RatingInfo[],
  o?: HighTideParams
) => {
  if (!resp || !o) return {};

  const risks: any = {};
  if ((o.hazards?.length ?? 0) > 0 && (o.consequences?.length ?? 0) > 0) {
    o.hazards?.forEach((hazard) => {
      o.consequences?.forEach((consequence) => {
        const risk = resp.find(
          (r) =>
            r.consequence === consequence &&
            // an empty hazard means it is the aggregate value so only use
            // that if we are looking at the aggregate selection
            (r.hazard === hazard ||
              (!r.hazard &&
                (hazard === AGGREGATE || hazard === ALL_HAZARDS))) &&
            r.ref_id === asset.id
        );
        risks[`${hazard}_${consequence}`] = risk?.risk_rating ?? "";
        risks.highestRiskScore = Math.max(
          risks.highestRiskScore || 0,
          risk?.risk_sort_score || 0
        );
      });
    });
  }
  return risks;
};

const getRiskColumnsPlaceholder = (placeholder: string, o?: HighTideParams) => {
  const risks: any = {};
  if ((o?.hazards?.length ?? 0) > 0 && (o?.consequences?.length ?? 0) > 0) {
    o?.hazards?.forEach((hazard) => {
      o.consequences?.forEach((consequence) => {
        risks[`${hazard}_${consequence}`] = placeholder;
      });
    });
  }
  return risks;
};

interface PathOptions {
  isGroup: boolean;
  assetId?: string | string[];
  groupId?: string | string[];
  assessmentId?: string;
  from?: string | string[];
}

export const getPath = (options: PathOptions) => {
  const { isGroup, assetId, assessmentId, from, groupId } = options;

  if ((!isGroup && !assetId) || !assessmentId || !from || (isGroup && !groupId))
    return;
  return isGroup
    ? `/groups/${groupId}/risk-metrics/${assessmentId}?from=${from}`
    : `/assets/${assetId}/risk-metrics/${assessmentId}?from=${from}${
        groupId ? "&groupId=" + groupId : ""
      }`;
};

const getShortPopulation = (asset: CreateAssetResponse): string =>
  asset?.total_building_population && asset?.total_building_population > 1
    ? `${asset.total_building_population} people`
    : `${asset.total_building_population} person`;

export const getCharacteristics = (
  headerLabels: AssetHeaderLabelSetting,
  asset?: CreateAssetResponse,
  options?: string[]
): Record<string, string | null> => {
  if (!asset) return {};

  const defaults = {
    [headerLabels?.primary_use]: asset.primary_use?.[0] ?? null,
    [headerLabels?.population]: asset.total_building_population
      ? getShortPopulation(asset)
      : null,
    [headerLabels?.replacement_value]: asset.replacement_cost
      ? formatMoney(asset.replacement_cost, 0)
      : asset.gross_asset_value
      ? formatMoney(asset.gross_asset_value)
      : null,
    [headerLabels?.area]: asset.footprint
      ? `${formatNumber(asset.footprint, "standard")} sq. ft`
      : null,
    [headerLabels?.status]: `${asset.status}`,
    [headerLabels?.construction_year]: `${asset.year_of_construction ?? ""}`,
    "Floor area": asset.total_area
      ? `${formatNumber(asset.total_area, "standard")} sq. ft`
      : null,
    "Number of floors": `${asset.n_above} above ground, \n ${asset.n_basement} basement`,
  };
  const defaultOptions = Object.values(headerLabels ?? {});
  const _options = options ?? defaultOptions;

  const filtered = Object.fromEntries(
    Object.entries(defaults).filter(([key]) =>
      _options?.some((option) => option === key)
    )
  );

  return filtered;
};

export const getAssetType = (asset?: AssetBasicResponse) => {
  const isArea = shouldShowArea(asset ? [asset] : []);
  const assetType = isArea ? ShapeType.AREA : ShapeType.POINT;
  return assetType;
};

export const shouldShowArea = (assets: AssetBasicResponse[]) => {
  if (!assets || assets.length === 0) return false;

  const asset = assets[0];
  return hasFootprintGeometry(asset);
};

export const hasFootprintGeometry = (asset?: AssetBasicResponse) => {
  if (!asset) return false;
  if (!(asset as any).footprint_geometry) return false;

  const footprint = (asset as any)?.footprint_geometry;
  if (isEmptyObject(footprint)) return false;

  return true;
};

export const getSelectedTab = (pathname: string) => {
  if (pathname.includes("risk-ratings")) return "risk-ratings";
  if (pathname.includes("adjacency")) return "adjacency";
  if (pathname.includes("strategies")) return "strategies";
  if (pathname.includes("files")) return "files";
  if (!pathname.includes("risk-metrics")) return "risk-rating-summary";
  return "assets";
};

interface Options {
  consequences: ConsequenceType[];
  hazards: HazardsEnum[];
  maps: ExportMap[];
}

export const getPreviewPath = (
  assetId: string,
  selectedIds: Ids,
  options: Options,
  isDownload?: boolean
) => {
  const { consequences, hazards, maps } = options;

  const prefix = isDownload ? `` : `/assets/${assetId}/`;
  let url = "preview?";

  url += `first_rating=${selectedIds.a}`;
  url += selectedIds.b ? `&second_rating=${selectedIds.b}` : "";

  consequences.forEach((c, i) => (url += `&${CONSEQUENCE_PREFIX[i]}=${c}`));
  hazards.forEach((h, i) => (url += `&${HAZARD_PREFIX[i]}=${h}`));
  maps.forEach((m, i) => {
    const assessmentId = `&${ASSESSMENT_PREFIX[i]}=${m.assessmentId}`;
    const intensityMeasure = `&${INTENSITY_MEASURE_PREFIX[i]}=${m.intensityMeasure}`;
    const returnPeriod = `&${RETURN_PERIOD_PREFIX[i]}=${m.returnPeriod}`;
    url += `${assessmentId}${intensityMeasure}${returnPeriod}`;
  });

  return `${prefix}${url}`;
};

export const getHazardTextLookup = (type?: HazardType) => {
  if (type === HazardType.FLOOD) {
    return (
      <>
        The flood hazard curve requires an asset-specific modification to
        convert the exterior flood depth relative to the external ground
        elevation (red) into the interior flood depth relative to the asset’s
        finished floor elevation (FFE) (green). This provides a measure of the
        depth of flooding that components inside the building would experience.
        At present, the interior flood depth is equal to the exterior flood
        depth minus the asset’s FFE relative to the ground elevation.
      </>
    );
  }

  if (type === HazardType.WIND) {
    return (
      <>
        The wind hazard curve requires a local terrain adjustment to convert the
        open terrain wind speed (red) into the site-specific wind speed (green).
        The site-specific wind speed is equal to the open terrain wind speed
        multiplied by the wind speed adjustment factor, which accounts for
        surface roughness of the given site.
      </>
    );
  }

  return <></>;
};

export const getHazardIntensityMeasureText = (type?: IntensityMeasureType) => {
  if (type === IntensityMeasureType.FLOOD_DEPTH) {
    return (
      <>
        <b>Flood depth</b>: Maximum water depth at the asset location relative
        to the surrounding ground (example units: <i>feet</i>)
      </>
    );
  }

  if (type === IntensityMeasureType.ADJACENT_FLAME_LENGTH) {
    return (
      <>
        <b>Adjacent flame length</b>: The maximum flame length within 30 meters
        of the asset location (example units: <i>feet</i>)
      </>
    );
  }

  if (type === IntensityMeasureType.FLAME_LENGTH) {
    return (
      <>
        <b>Flame length</b>: The flame length at the asset location (example
        units: <i>feet</i>)
      </>
    );
  }

  if (type === IntensityMeasureType.EMBER_COUNTS) {
    return (
      <>
        <b>Ember count</b>: A relative measure of density of embers landing at
        the asset location. (unitless)
      </>
    );
  }

  if (type === IntensityMeasureType.PEAK_GROUND_ACCELERATION) {
    return (
      <>
        <b>Peak ground acceleration</b>: Shaking intensity at the surface at the
        asset location (example units: <i>g</i>)
      </>
    );
  }

  if (type === IntensityMeasureType.MAX3SGUST) {
    return (
      <>
        <b>Wind speed</b>: 3-second gust wind speed at the asset location
        (example units: <i>miles per hour</i>)
      </>
    );
  }

  return <></>;
};

export const isValidNickname = (a: any) => String(a).indexOf(" ") === -1;

type GeolocationBodyOptions = {
  isEdit: boolean;
  isUpdated: boolean;
  defaultQuery: any;
  query?: SiteCentroidRequest | FootprintByGeolocationQuery;
};

export const getGeolocationBody = (options: GeolocationBodyOptions) => {
  const { isEdit, query, isUpdated, defaultQuery } = options;

  if (!isEdit) return query ?? defaultQuery;
  if (query && isUpdated) return query;

  return defaultQuery;
};
